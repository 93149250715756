<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
body,
span,
label,
div {
  padding: 0;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none !important;
}

.clear {
  clear: both;
}

.red {
  color: #fe3e12 !important;
}

.green {
  color: #12fe81 !important;
}

.grey {
  color: rgba(128, 128, 128, 0.8) !important;
}

html,
body,
form {
  overflow-y: auto;
  height: 100%;
}

/* 滚动条样式 */
::-webkit-scrollbar {
  width: 0px;
  height: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.9);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(66, 66, 66, 1);
  -webkit-box-shadow: inset 0 0 6px rgba(227, 227, 227, 87.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(227, 227, 227, 0.5);
}

.search {
  margin: 24px 0;
}

.content {
  max-height: calc(100vh - 72px);
  overflow-y: auto
}

.search+.content {
  max-height: calc(100vh - 152px);
}
</style>
