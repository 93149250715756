import axios from 'axios'
import { message } from 'ant-design-vue';
import { baseURL } from '@/config/setting.config'

// create an axios instance
const service = axios.create({
  baseURL: baseURL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30 * 1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res instanceof Blob) {
      return res
    }
    // console.log(res)
    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== 200 && res.status !== 20000 && res.code !== 0) {
      message.error(res.message || res.msg || res.data || 'Error')

      return Promise.reject(res.message || res.msg || res.data || 'Error')
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    message.error(error.message)
    return Promise.reject(error)
  }
)

export default service
