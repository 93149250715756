import { getUserInfo, login, logout } from '@/api/user'
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from '@/utils/accessToken'
import { title, tokenName } from '@/config/setting.config'
import { message, notification } from 'ant-design-vue'
import router from '@/router'

const state = () => ({
  accessToken: getAccessToken(),
  userId: '',
  username: '',
  userNumber: '',
  avatar: '',
})
const getters = {
  accessToken: (state) => state.accessToken,
  username: (state) => state.username,
  userNumber: (state) => state.userNumber,
  avatar: (state) => state.avatar,
  userId: (state) => state.userId,
}
const mutations = {
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 设置accessToken
   * @param {*} state
   * @param {*} accessToken
   */
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    setAccessToken(accessToken)
  },
  /**
   * @description 设置用户名
   * @param {*} state
   * @param {*} username
   */
  setUsername(state, username) {
    state.username = username
  },
  /**
   * @description 设置用户名
   * @param {*} state
   * @param {*} username
   */
  setUserNumber(state, userNumber) {
    state.userNumber = userNumber
  },
  /**
   * @description 设置用户名id
   * @param {*} state
   * @param {*} username
   */
  setUserId(state, userId) {
    state.userId = userId
  },
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 设置头像
   * @param {*} state
   * @param {*} avatar
   */
  setAvatar(state, avatar) {
    state.avatar = avatar
  },
}
const actions = {
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async login({ commit }, userInfo) {
    const res = await login(userInfo)
    const accessToken = res[tokenName]
    commit('setAccessToken', accessToken || userInfo.number)
    const hour = new Date().getHours()
    const thisTime =
      hour < 8
        ? '早上好'
        : hour <= 11
        ? '上午好'
        : hour <= 13
        ? '中午好'
        : hour < 18
        ? '下午好'
        : '晚上好'
    notification.open({
      message: `欢迎登录${title}`,
      description: `${thisTime}！`,
    })
    // } else {
    //   message.error(`登录接口异常，未正确返回${tokenName}...`)
    // }
  },
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  async getUserInfo({ commit, dispatch }) {
    const { data } = await getUserInfo()
    if (!data) {
      message.error(`验证失败，请重新登录...`)
      await dispatch('resetAll')
      router.push('/login')
      return false
    }
    let { number } = data
    if (number) {
      commit('setUserId', number)
      commit('setUsername', number)
      commit('setUserNumber', number)
    } else {
      message.error('用户信息接口异常')
    }
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('setAccessToken', '')
      removeAccessToken()
      resolve()
    })
  },

  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 退出登录
   * @param {*} { dispatch }
   */
  async logout({ dispatch }) {
    await logout(state.accessToken)
    await dispatch('resetAll')
    router.push('/login')
  },
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 重置accessToken、roles、ability、router等
   * @param {*} { commit, dispatch }
   */
  async resetAll({ dispatch }) {
    await dispatch('setAccessToken', '')
    removeAccessToken()
  },
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 设置token
   */
  setAccessToken({ commit }, accessToken) {
    commit('setAccessToken', accessToken)
  },
}
export default { state, getters, mutations, actions }
