import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import router from './router'
import store from './store'
import './permission'
import moment from 'moment'
import 'moment/locale/zh-cn';
moment.locale('zh-cn')
import Vant from 'vant';
import 'vant/lib/index.css';

// Viewer.setDefaults({
//     Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
// })

const app = createApp(App)
app.use(Vant);

app.use(Antd).use(router).use(store).mount('#app')
