<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider>
      <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline" style="margin-top: 60px;">
        <a-menu-item v-for="item in menu" :key="item.path" @click="goPage(item.path)">
          <span>{{ item.name }}</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0;position: relative;">
        <h2 style="padding: 0 24px;">{{ title }}</h2>
        <a-dropdown class="dropdown">
          <a class="ant-dropdown-link" @click.prevent>
            {{ userName }}，您好
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu @click="onClick">
              <a-menu-item key="resetPassword">重置密码</a-menu-item>
              <a-menu-item key="logout">退出</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-layout-header>
      <a-layout-content style="margin: 0 16px">
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
  <!-- 重置密码 -->
  <a-modal v-model:open="editOpen" :title="'重置密码'" @ok="handleOk" cancelText="取消" okText="确定">
    <a-form :model="formState" name="basic" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" autocomplete="off"
      ref="formRef">
      <a-form-item label="账号" name="number">
        <a-input v-model:value="formState.number" placeholder="账号" disabled>
        </a-input>
      </a-form-item>
      <a-form-item label="密码" name="password">
        <a-input
          v-model:value="formState.password"
          type="password"
          placeholder="密码"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="确认密码" name="checkPass">
        <a-input
          v-model:value="formState.checkPass"
          type="password"
          placeholder="确认密码"
        >
          <template v-slot:prefix>
            <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
          </template>
        </a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { title } from '@/config/setting.config'
import { useStore } from 'vuex'
import { resetPassword } from '@/api/user'
import { message } from 'ant-design-vue';
const store = useStore()
const router = useRouter();
console.log(router.currentRoute.value.name)
const selectedKeys = ref([router.currentRoute.value.name]);
let menu = [
  { name: '问卷管理', path: 'questionnaire' },
  { name: '短信模版', path: 'message' },
  { name: '信息维护', path: 'user' }
]
const userName = store.state.user.username
const userNumber = store.state.user.userNumber
// if (userNumber === 'admin') {
//   menu = [
//     { name: '日程管理', path: 'calendarEvent/calendarEvent' },
//     { name: '公告管理', path: 'announcement/announcement' },
//     { name: '操作日志', path: 'operate/operate' },
//   ]
// }

const goPage = (path) => {
  router.push(`/${path}`)
}

const onClick = ({ key }) => {
  switch (key) {
    case 'logout':
      store.dispatch('user/logout')
      break;
    case 'resetPassword':
      formRef.value?.resetFields()
      formRef.value?.clearValidate()
      editOpen.value = true
      break;
  }
}
// 密码重置
const formRef = ref()
let formState = reactive({
  number: userNumber,
  password: '',
  checkPass: ''
})
const editOpen = ref(false)
const validatePass = async (_rule, value) => {
    if (value === '') {
      return Promise.reject('请输入密码')
    } else {
      if (formState.checkPass !== '') {
        formRef.value.validateFields('checkPass')
      }
      return Promise.resolve()
    }
  }
  const validatePass2 = async (_rule, value) => {
    if (value === '') {
      return Promise.reject('请输入确认密码')
    } else if (value !== formState.password) {
      return Promise.reject('密码不一致')
    } else {
      return Promise.resolve()
    }
  }
const rules = {
  number: [{ required: true, message: '请输入账号!', trigger: 'change' }],
  password: [{ required: true, validator: validatePass, trigger: 'change' }],
  checkPass: [{ validator: validatePass2, trigger: 'change' }],
}
const handleOk = async () => {
  await formRef.value.validateFields()
  resetPassword(formState).then(() => {
    message.success('密码重置成功')
    editOpen.value = false
    store.dispatch('user/logout')
  })
}

</script>
<style scoped>
.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}

::v-deep .ant-menu-item-selected {
  border-radius: 0;
}
.dropdown {
  position: absolute;
  top: 0;
  right: 20px;
}
</style>