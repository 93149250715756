import request from '@/utils/request'

export async function login(data) {
  return request({
    url: '/auth/login.json',
    method: 'post',
    data,
  })
}
export function register(data) {
  return request({
    url: '/auth/register.json',
    method: 'post',
    data,
  })
}
export function resetPassword(data) {
  return request({
    url: '/auth/resetPassword.json',
    method: 'post',
    data,
  })
}
export function getUserInfo(params) {
  return request({
    url: '/auth/currentUser.json',
    method: 'get',
    params,
  })
}
export function logout() {
  return request({
    url: '/auth/logout.json',
    method: 'post',
  })
}
// 获取全部用户
export function getAllUser(params) {
  return request({
    url: '/auth/allUser.json',
    method: 'get',
    params,
  })
}

// 获取手机号列表
export function getUserList(params) {
  return request({
    url: '/user/list.json',
    method: 'get',
    params,
  })
}

// 删除手机号
export function delUser(params) {
  return request({
    url: '/user/deleteById.json',
    method: 'get',
    params,
  })
}

// 文件上传
export function upload(data) {
  return request({
    url: '/user/upload.json',
    method: 'post',
    data
  })
}
