/**
 * @description 导出默认通用配置
 */
const setting = {
  //默认的接口地址
  baseURL: process.env.NODE_ENV === 'development' ? 'lo' : '/',
  //标题 （包括初次加载雪花屏的标题 页面的标题 浏览器的标题）
  title: '自办件问卷管理',
  tokenName: 'accessToken',
  //token存储位置localStorage sessionStorage cookie
  storage: 'localStorage',
  httpURL: 'http://192.168.31.47:1111/'
}
module.exports = setting
