import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/wenjuan',
    component: () => import('@/views/wenjuan'),
    hidden: true,
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
  },
  {
    path: '/',
    redirect: '/404',
    hidden: true,
  },
  {
    path: '/',
    component: Layout,
    meta: {
      title: '问卷管理',
      icon: 'home-4-line',
    },
    children: [
      {
        path: 'questionnaire',
        name: 'questionnaire',
        component: () => import('@/views/questionnaire/index.vue'),
        meta: {
          title: '问卷管理',
          icon: 'home-4-line',
        },
      },
      {
        path: 'questionnaire-edit',
        name: 'questionnaire-edit',
        component: () => import('@/views/questionnaire/edit.vue'),
        meta: {
          title: '问卷编辑',
          icon: 'home-4-line',
        },
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    meta: {
      title: '短信模版',
      icon: 'home-4-line',
    },
    children: [
      {
        path: 'message',
        name: 'message',
        component: () => import('@/views/message/index.vue'),
        meta: {
          title: '短信模版',
          icon: 'home-4-line',
        },
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    meta: {
      title: '信息维护',
      icon: 'home-4-line',
    },
    children: [
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/user/index.vue'),
        meta: {
          title: '信息维护',
          icon: 'home-4-line',
        },
      },
    ],
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
})

export default router