const state = () => ({
  editQuestionnaire: '' // 修改状态
})
const getters = {
  editQuestionnaire: (state) => state.editQuestionnaire
}
const mutations = {
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 设置accessToken
   * @param {*} state
   * @param {*} accessToken
   */
  setEditQuestionnaire(state, questionnaire) {
    state.editQuestionnaire = questionnaire
  }
}
const actions = {
}
export default { state, getters, mutations, actions }
